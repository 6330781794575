




import { BaseComponent, Component } from '@/components/BaseComponent';
import Spinner from '@/components/Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class Authorize extends BaseComponent {
  async created() {
    const params = new URLSearchParams(window.location.search);
    const result = await this.pds.authorize(params);
    if (result.isSuccess()) {
      const clientId = params.get('client_id');
      if (clientId) {
        this.authorization.setClientId(clientId);
      }
      this.authorization.setClientData(result.value.payload);
      this.session.authorize(result.value.payload);
    }
    this.router.changePage(result);
  }
}
